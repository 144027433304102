import React from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export const ASRTTS = (data) => {
  let uniqueDates = null
  let counts = null

  if (data.data.users && Array.isArray(data.data.users)) {
    // Extract unique dates and their counts
    const dateCountsMap = new Map();
    data.data.users.forEach(item => {
      const date = new Date(item.created_at).toISOString().split('T')[0];
      dateCountsMap.set(date, (dateCountsMap.get(date) || 0) + 1);
    });

    // Separate unique dates and their counts into separate arrays
    uniqueDates = Array.from(dateCountsMap.keys());
    counts = Array.from(dateCountsMap.values());

  }
  if (data.value && Array.isArray(data.value)) {
    const dateCountsMap = new Map();
    data.value.forEach(item => {
      const date = new Date(item.created_at).toISOString().split('T')[0];
      dateCountsMap.set(date, (dateCountsMap.get(date) || 0) + 1);
    });
    uniqueDates = Array.from(dateCountsMap.keys());
    counts = Array.from(dateCountsMap.values());
  }

  const options = {
    chart: {
      type: "line",
      height: 280,
      width: 750,
    },
    title: {
      text: "Total Requests",
    },
    subtitle: {
      text: [],
      align: "left",
    },
    tooltip: {
      valueSuffix: "",
    },
    xAxis: {
      categories: uniqueDates,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Total Count",
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: [
          {
            enabled: true,
            distance: 20,
          },
          {
            enabled: true,
            distance: -40,
            format: "{point.Seconds:.1f}s",
            style: {
              //   fontSize: "1.2em",
              textOutline: "none",
              opacity: 0.7,
            },
            filter: {
              operator: ">",
              property: "Count",
              value: 10,
            },
          },
        ],
      },
    },
    credits: {
      enabled: false,
    },
    series: [

      {
        name: "Total Count",
        data: counts,
        color: "Brown",
      },
    ],
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
