import React, { useEffect, useState} from 'react'
import { message, Select } from 'antd';
import { ASRTTS } from './ASRTTS';
import { TexttoText } from './TexttoText';
import { TexttoSpeech } from './TexttoSpeech';
import { TableComponent } from './TableComponent';
import { SpeechToText } from './SpeechToText';
import { useSelector ,useDispatch } from "react-redux";
import { trackUserSpeechLogs } from '../../slices/analyticsSlice';
import { speechModeOptions } from '../../slices/analyticsSlice';

export const TrackUsage = () => {
 
  const { data } = useSelector((state) => state.auth.login);
  const user_id = data?.user_id
  const dispatch = useDispatch();


  const [dataSource, setDataSource] = useState([])
  const [options, setOptions] = useState();
  const [filteredDataSource, setFilteredDataSource] = useState()
  const [selectedComponent, setSelectedComponent] = useState();
  const [monthDates, setMonthDates] = useState({
    startDate: '',
    endDate: '',
  });
  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'api-key': data?.api_key
    }
  };

  const headers = {
    
      'Content-Type': 'application/json',
      'api-key': data?.api_key
    
  };

  const handleChange = async (value) => {
    setSelectedComponent(value);
    dispatch(trackUserSpeechLogs({
      user_id: user_id,
      from_date: monthDates.startDate,
      to_date: monthDates.endDate,
      path: value,
      headers: headers
    }))
    .then((res) => { 
      setFilteredDataSource({})
      setFilteredDataSource(res.payload?.users || [])
    })
    .catch((err) => {
      console.log(err);
      if (err.response?.data?.detail) {
        message.error(err.response.data.detail);
      } else {
        message.error("Something went wrong! Contact Minsky Support Team");
      }
    });
  };
  
  


  const renderSelectedComponent = () => {
    if (selectedComponent == 'speech-to-text') {
      return (<SpeechToText value={filteredDataSource} />);
    } else if (selectedComponent == 'text-to-text') {
      return (<TexttoText data={dataSource} value={filteredDataSource} />
      );
    } else if (selectedComponent == 'text-to-speech') {
      return (<TexttoSpeech data={dataSource} value={filteredDataSource} />
      );
    }
    else {
      return <TableComponent data={dataSource} />;

    }
  };





  useEffect(() => {

  // If data is not present in sessionStorage, make an API call
  const fetchData= async () => {
    dispatch(speechModeOptions({
      user_id: user_id,
      headers: headers 
    }))
    .then((res) => { 
      setOptions(res.payload?.api_path || []);
    })
    .catch((err) => {
      console.log(err);
      if (err.res?.payload?.detail) {
        message.error(err.res?.payload?.detail);
      } else {
        message.error("Something went wrong! Contact Minsky Support Team");
      }
    });
  };

  fetchData();
  }, []);


  useEffect(() => {
    const fetch = async () => {
      
      const dates = getCurrentMonthDates();
      setMonthDates(dates);
  
      dispatch(trackUserSpeechLogs({
        user_id: user_id,
        from_date: dates.startDate,
        to_date: dates.endDate,
        path: 'speech-to-text',
        headers: headers 
      }))
      .then((res) => { 
        setDataSource(res.payload || []);
      })
      .catch((err) => {
        console.log(err);
        if (err.res?.payload?.detail) {
          message.error(err.res?.payload?.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
      });
    };
  
    fetch();
  
  }, [])

  

  function getCurrentMonthDates() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Set the date to the first day of the current month
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
    const formattedStartDate = firstDayOfMonth.toISOString().split('T')[0];

    // Set the date to the current day
    const currentDate = new Date();
    const formattedEndDate = currentDate.toISOString().split('T')[0];

    return {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    };
  }

  
  useEffect(() => {
  const fetch = async () => {
    
    const dates = getCurrentMonthDates();
    setMonthDates(dates);

    dispatch(trackUserSpeechLogs({
      user_id: user_id,
      from_date: dates.startDate,
      to_date: dates.endDate,
      path: 'speech-to-text',
      headers: headers 
    }))
    .then((res) => { 
      setDataSource(res.payload || []);
    })
    .catch((err) => {
      console.log(err);
      if (err.res?.payload?.detail) {
        message.error(err.res?.payload?.detail);
      } else {
        message.error("Something went wrong! Contact Minsky Support Team");
      }
    });
  };

  fetch();

}, [])


  

  return (
    <div >
      <div style={{ fontSize: '20px', marginBottom: '20px' }}>Service Usage</div>
      <div style={{ display: "flex", justifyContent: 'flex-end' }}>
        <Select
          style={{ width: "200px" }}
          placeholder="filter"
          optionFilterProp="children"
          onChange={handleChange}
          // value={selectedComponent}
          // options={options}
          defaultValue="speech-to-text"
        >
          {Array.isArray(options) && options.length > 0 ? (
            options.map(option => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))
          ) : null}
        </Select>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ASRTTS data={dataSource} value={filteredDataSource} />
      </div>
      {renderSelectedComponent()}
    </div>
  )
}
