
const ENV_PROD = false; // For proudction make it true, don't change in the local environment
let minsky_url = "";

if (ENV_PROD) {
  minsky_url = "https://api.minsky.app"
} else {
  minsky_url = "https://uat.minsky.app/apis";
}



const API_LIVE = {
  BASE_URL_MINSKY: minsky_url,
  
  //----------------------------------------------------------
  login:`${minsky_url}/user/login/`,
  register:`${minsky_url}/user/register/`,
  forgetPassword:`${minsky_url}/user/forgot/password`,
  changePassword:`${minsky_url}/user/change/password/`,
  textToText: `${minsky_url}/text-to-text/generate-request/`,
  speechToText: `${minsky_url}/speech-to-text/generate-request/`,
  textToSpeech: `${minsky_url}/text-to-speech/generate-request/`,
  changePassword: `${minsky_url}/user/change/password/`,
  updateRecord:`${minsky_url}/user/update/record/`,
  generateAPIKey: `${minsky_url}/user/update/key/`,
  userBalance:`${minsky_url}/user/balance`,
  GetModelOptions: `${minsky_url}/track/method/`,
  TrackUserLogs: `${minsky_url}/track/logs/`,
  updatePrompt: `${minsky_url}/text-to-text/update/prompt/`,
  deletePrompt: `${minsky_url}/text-to-text/delete/prompt/`,
  createPrompt: `${minsky_url}/text-to-text/create/prompt/`,
  getPrompt: `${minsky_url}/text-to-text/get/prompt/`,
  textToTextModel: `${minsky_url}/text-to-text/together/ai/model/name/`,
  textToSpeechLanguage: `${minsky_url}/text-to-speech/language/`,
  speechToTextModel: `${minsky_url}/speech-to-text/model/`,
  textToSpeechModel: `${minsky_url}/text-to-speech/model/`,
  //-----------------------------------------------------------------------


};

const MINSKY_URL = API_LIVE;

export default MINSKY_URL;



