import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {

};




export const trackUserSpeechLogs = createAsyncThunk(
  "trackUserLogs/trackUserSpeechLogs",
  async ({ user_id, from_date, to_date, path, headers }) => {
      const response = await axiosInstance.get(
        `${MINSKY_URL.TrackUserLogs}?user_id=${user_id}&from_date=${from_date}&to_date=${to_date}&path=${path}`,
        { headers })
    .catch((error) => {
      return error.response;
    });

    return response.data;
  }
);


export const speechModeOptions = createAsyncThunk(
  "speech/speechModeOptions",
  async ({user_id, headers}) => {
   
    const response = await axiosInstance
      .get(
        `${MINSKY_URL.GetModelOptions}?user_id=${user_id}`,
        { headers })
      .catch((error) => {
        return error.response;
      });

    return response.data;
  }
);




  

  export const analyticsSlice = createSlice({
    name: "analytics",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(trackUserSpeechLogs.pending, (state, action) => {
          state.status = "pending";
        })
        .addCase(trackUserSpeechLogs.fulfilled, (state, action) => {
  
        })
        .addCase(trackUserSpeechLogs.rejected, (state, action) => {
          state.status = "failed";
        })
 //------------------------------------------------------------------
        .addCase(speechModeOptions.pending, (state, action) => {
          state.status = "pending";
        })
        .addCase(speechModeOptions.fulfilled, (state, action) => {
  
        })
        .addCase(speechModeOptions.rejected, (state, action) => {
          state.status = "failed";
        })

    },
  });
  
  // export const {} = analyticsSlice.actions;
  export const analyticsReducer = analyticsSlice.reducer;