import TextArea from 'antd/es/input/TextArea'
import React, { useRef, useState, useContext, useEffect } from 'react'
import { Button, message } from 'antd';
import { LoginContext } from './ContextApis/LoginContext';
import { ModelContext } from './ContextApis/ModelContext';
import { textToSpeech } from './slices/speechSlice';
import { useDispatch,useSelector } from "react-redux";


export const TexttoSpeech = () => {

    const dispatch = useDispatch();

    const [audioSrc, setAudioSrc] = useState("");
    const [textToConvert, setTextToConvert] = useState('');
    const audioRef = useRef(null);
    const [showAudioPlayer, setShowAudioPlayer] = useState(false);
    const { AuthData } = useContext(LoginContext);
    const { selectedVoice, selectedLanguage } = useContext(ModelContext)

    const { isLoading } = useSelector((state) => state.speech.texttospeech);




    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
            alignItems: 'center',
            backgroundColor: '#1E1E1E',
            padding: '20px',
        },
        audioPlayer: {
            width: '100%',
            maxWidth: '400px',
            backgroundColor: '#333',
            borderRadius: '8px',
        },
    };


    const handleConvert = async () => {

        
        const requestBody = {
          text: textToConvert,
          language: selectedLanguage || 'English',
          voice: selectedVoice || 'male',
        };
        
        const headers = {
          'Content-Type': 'application/json',
          'api-key': AuthData
        };
      
        dispatch(textToSpeech({ requestBody, headers }))
          .then((response) => {

            // console.log(response)
            // Handle successful response
            const audioSource = response.payload.filepath;
            setAudioSrc(audioSource || []);
            setShowAudioPlayer(true);
            
          })
          .catch((error) => {
            if (error.payload && error.payload.detail) {
              message.error(error.payload.detail);
            } else {
              message.error("Something went wrong! Contact Minsky Support Team");
            }

          });
      };

      
  

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.load();
        }
    }, [audioSrc]);
    return (

        <div className='main-text-speech-conatiner'>
            <div >
                <TextArea placeholder='Type text here' style={{ height: '40vh' }}
                    onChange={(e) => setTextToConvert(e.target.value)} />
            </div>
            <div className='speech-convert-button'>
                <Button type='primary' loading={isLoading} onClick={handleConvert}
                    disabled={textToConvert.trim() === ''}>Convert</Button>
            </div>
            {showAudioPlayer && (
                <div className='play-audio-player'>
                    <audio id="audioPlayer" ref={audioRef} style={styles.audioPlayer} controls>
                        <source src={audioSrc} type="audio/mp3" />
                    </audio>
                </div>
            )}
        </div>
    )
}
