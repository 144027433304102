import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { LoginContext } from "../ContextApis/LoginContext";
import { APIKeys } from "../Components/APIKeys";
import { Profile } from "../Components/Profile";
import { TrackUsage } from "../Components/Dashboard/TrackUsage";
import { Home } from "../Payments/Home";
import { Checkout } from "../Payments/Checkout";

export const Routers = () => {
  const { isAuthenticated } = useContext(LoginContext)
  return (
    <div >
      {isAuthenticated ?
        <Routes>
          <Route path="profile" element={<Profile />}></Route>
          <Route path="apikeys" element={<APIKeys />}></Route>
          <Route path="analytics" element={<TrackUsage />}></Route>
          <Route path="billing" element={<Home />}></Route>
          <Route path="checkout" element={<Checkout />}></Route>
        </Routes>
        : <></>}
    </div>
  );
};
