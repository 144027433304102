import React from 'react'

import { ChangePassword } from './ChangePassword'
import { Collapse } from 'antd';
import { RecordUpdate } from './RecordUpdate'

// import FormContainer from 'react-bootstrap/FormContainer';
const { Panel } = Collapse;
export const Profile = () => {
  return (
    <div className='profile-main-section' >
      <div style={{ width: '40%' }}>
        <RecordUpdate />
        <div style={{ marginTop: '20px' }}>
          <Collapse accordion>
            <Panel header="Change Password" key="1">
              <div style={{ marginTop: '20px' }}>
                <ChangePassword />
              </div>
            </Panel>
          </Collapse>
        </div>
      </div>
    </div >
  )
}
