import React, { useState, useContext, useEffect } from "react";
import { PlaygroundRouters } from "../Navigation/PlaygroundRouters";
import MinskyLogo from '../Assets/minskyLogo1.png'
import MinskyShortM from '../Assets/minskyLogoM.png'
import { Outlet, useNavigate} from "react-router-dom";
import { ModelContext } from "../ContextApis/ModelContext";
import { useLocation } from 'react-router-dom';
import { LoginContext } from "../ContextApis/LoginContext";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {
    WechatOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    BookOutlined,
    FileImageOutlined,
    PoweroffOutlined,

    DashboardOutlined

} from "@ant-design/icons";
import { Layout, Menu, Button, theme, message, Statistic } from "antd";
import { ModelFilters } from "../Model/ModelFilters";
import './Layout.css';
import header_data from '../Data/header_description.json'
import { textToTextModelNames , textToSpeechLangNames,textToSpeechModelNames,speechToTextModelNames} from "../slices/modelSlice";
import { useDispatch } from "react-redux";
const { Header, Sider, Content } = Layout;

export const LayoutPlayground = () => {
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const [modelName, setmodelName] = useState()
    const [selectedModelName, setselectedModelName] = useState("MISTRAL_8X7B")
    const [selectedMenu, setselectedMenu] = useState("chat")
    const [selectedLanguage, setselectedLanguage] = useState("English")
    const [maxSeq, setMaxSeq] = useState(null);
    const [outputLengthRange, setOutputLengthRange] = useState([128, maxSeq]);
    const [temperatureRange, setTemperatureRange] = useState([0.7, 2]);
    const [topPRange, setTopPRange] = useState([0.7, 2]);
    const [topKRange, setTopKRange] = useState([50, 100]);
    const [repetitionPenaltyRange, setRepetitionPenaltyRange] = useState([1.2, 2]);
    const [selectedVoice, setSelectedVoice] = useState("male")
    const [allanguages, setAllanguages] = useState('')
    const [speechToTextModel, setspeechToTextModel] = useState()
    const [speechToTextSelectedModel, setspeechToTextSelectedModel] = useState('ASR_HQ')
    const [textToSpeechmodel, settextToSpeechmodel] = useState()
    const [textToSpeechmodelSelected, setTextToSpeechmodelSelected] = useState('VOICE_HQ')
    const [systemPrompt, setSystemPrompt] = useState('You are an assistant and your task is to help with user queries')
    const { userServicebalance, setUserId, setIsAuthenticated, setFirstName, setLastName, setProfileEmail, setProfilePhone, setAuthData, AuthData } = useContext(LoginContext);
    const navigate = useNavigate();
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    // const hide = () => {
    //     setOpen(false);
    // };
    // const handleOpenChange = (newOpen) => {
    //     setOpen(newOpen);
    // };
    const handleDashboardNavigation = () => {
        navigate('/dashboard/profile');
    };
    const handleLogout = () => {
        sessionStorage.clear();
        setIsAuthenticated(false);
        setAuthData(false);
        setFirstName(false);
        setLastName(false);
        setProfileEmail(false);
        setProfilePhone(false);
        setUserId(false);
        navigate("/");
    };
    const handleMenuClick = ({ key }) => {
        setselectedMenu(key);
        navigate(key)
    };
    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const key = pathSegments[pathSegments.length - 1];
        setselectedMenu(key || 'chat');
    }, [location]);

    const navigateAPIDoc = () => {
        window.open('https://uat.minsky.app/docs/redoc/', '_blank');
    }

    let textToTextheaderComponent;

    if (selectedModelName === 'MISTRAL_7B') {
        textToTextheaderComponent = header_data.MISTRAL_7B
    } else if (selectedModelName === 'MISTRAL_8X7B') {
        textToTextheaderComponent = header_data.MISTRAL_8X7B
    } else if (selectedModelName === 'LLAMA_7B') {
        textToTextheaderComponent = header_data.LLAMA_7B
    }
    else if (selectedModelName === 'LLAMA_70B') {
        textToTextheaderComponent = header_data.LLAMA_70B
    }
    else if (selectedModelName === "CODELLAMA_70B_INST") {
        textToTextheaderComponent = header_data.CODELLAMA_70B_INST
    }
    else if (selectedModelName === "DEEPSEEK_CODER_33B") {
        textToTextheaderComponent = header_data.DEEPSEEK_CODER_33B
    }

    // let speechToTextheaderComponent;

    // if (selectedModelName === 'ASR_HQ') {
    //     speechToTextheaderComponent = header_data.MISTRAL_7B
    // } else if (selectedModelName === 'ASR_FS') {
    //     speechToTextheaderComponent = header_data.MISTRAL_8X7B
    // }

    // let speechToTextheaderComponent;

    // if (textToSpeechmodelSelected === 'VOICE_HQ') {
    //     speechToTextheaderComponent = header_data.VOICE_HQ
    // } else if (textToSpeechmodelSelected === 'VOICE_FS') {
    //     speechToTextheaderComponent = header_data.VOICE_FS
    // }
    // console.log(textToSpeechmodelSelected)


    let textToSpeechHeaderComponent;
    if (textToSpeechmodelSelected === 'VOICE_HQ') {
        textToSpeechHeaderComponent = header_data.VOICE_HQ
    } else if (textToSpeechmodelSelected === 'VOICE_FS') {
        textToSpeechHeaderComponent = header_data.VOICE_FS
    }

    const headers =  {
        'Content-Type': 'application/json',
        'api-key': AuthData
    };

    const getModelData = () => {
           dispatch(textToTextModelNames({  headers }))
               .then((res) => {
                   console.log(res)
                   const modelnames = res?.payload;
                   setmodelName(modelnames || []);
               })
               .catch((error) => {
                   if (error.payload && error.payload.detail) {
                       message.error(error.payload.detail);
                   } else {
                       message.error('Something went wrong! Contact Minsky Support Team');
                   }
               });
            }
    useEffect(() => {
        getModelData();
    }, []);


    const getLanguages = () => {
    
        dispatch(textToSpeechLangNames({  headers }))
            .then((res) => {
                const language = res.payload;
                setAllanguages(language || []);
            })
            .catch((error) => {
                if (error.payload && error.payload.detail) {
                    message.error(error.payload.detail);
                } else {
                    message.error('Something went wrong! Contact Minsky Support Team');
                }
            });
         }

    useEffect(() => {
        getLanguages();
    }, []);



    const getSpeechToTextModel = () => {
    
        dispatch(speechToTextModelNames({  headers }))
            .then((res) => {
                const modelnames = res?.payload;
                setspeechToTextModel(modelnames || []);
            })
            .catch((error) => {
                if (error.payload && error.payload.detail) {
                    message.error(error.payload.detail);
                } else {
                    message.error('Something went wrong! Contact Minsky Support Team');
                }
            });
         }

    useEffect(() => {
        getSpeechToTextModel();
    }, []);


    const getTextToTextmodel = () => {
    
        dispatch(textToSpeechModelNames({  headers }))
            .then((res) => {
                const modelnames = res?.payload;
                console.log(modelnames)
                settextToSpeechmodel(modelnames || []);
            })
            .catch((error) => {
                if (error.payload && error.payload.detail) {
                    message.error(error.payload.detail);
                } else {
                    message.error('Something went wrong! Contact Minsky Support Team');
                }
            });
         }


    const handleProfileBalanceClick = () => {
        navigate("/dashboard/billing")
    };

    useEffect(() => {
        getTextToTextmodel();
    }, []);

    return (
        <>
            <Layout>
                <Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    className="layout-slider-playground"
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        background: 'black'
                    }}
                >
                    <div className="demo-logo-vertical" />
                    <div className="" style={{ color: "white" }}>
                        {" "}
                        <div className="comp-logo">
                            {collapsed ?
                                <img src={MinskyShortM} alt="minisky" style={{ width: 80, height: '3vh' }}></img>
                                :
                                <img src={MinskyLogo} alt="minisky" style={{ width: 140 }}></img>
                            }
                        </div>

                    </div>
                    <Menu
                        theme="dark"
                        style={{ background: 'black', color: '#a6adb4' }}
                        mode="inline"
                        // defaultSelectedKeys={["chat"]}
                        selectedKeys={[selectedMenu]}
                        onClick={handleMenuClick}
                        items={[
                            {
                                key: "chat",
                                icon: <WechatOutlined />,
                                label: "Text to Text",
                            },
                            {
                                key: "transcribe",
                                icon: <BookOutlined />,
                                label: "Speech to Text",
                            },
                            {
                                key: "voice",
                                icon: <FileImageOutlined />,
                                label: "Text to Speech",
                            }
                        ]}
                    />

                    <div className={collapsed ? "main-collapsed-siderbar-footer" : "main-siderbar-footer"}>
                        <hr style={{ color: 'white' }}></hr>
                        <div
                            onClick={navigateAPIDoc}
                            className="footer_sider_bar"
                        >
                            {collapsed ? <BookOutlined /> : <><BookOutlined style={{ marginRight: '8px' }} />
                                API Docs</>}
                        </div>

                        <div
                            onClick={handleDashboardNavigation}
                            className="footer_sider_bar"
                        >
                            {collapsed ? <DashboardOutlined /> : <><DashboardOutlined style={{ marginRight: '8px' }} />
                                Dashboard</>}
                        </div>

                        <div
                            onClick={handleLogout}
                            className="footer_sider_bar"
                        >
                            {collapsed ? <PoweroffOutlined /> : <><PoweroffOutlined style={{ marginRight: '8px' }} />
                                Logout</>}
                        </div>
                    </div>

                </Sider>
                <Layout style={collapsed ? { marginLeft: 80 } : { marginLeft: 200 }}>
                    <div>
                        <Header
                            style={{
                                padding: 0,
                                background: colorBgContainer,
                                display: 'flex',
                                justifyContent: "space-between",
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                type="text"
                                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => setCollapsed(!collapsed)}
                                style={{
                                    fontSize: "16px",
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <div className="profile-balance" onClick={handleProfileBalanceClick}>
                                <Statistic title={
                                    <div className="balance-main">
                                        <AccountBalanceWalletIcon fontSize="large" style={{ color: '#896ACB' }} />
                                        <span className="balance-fontsize">{userServicebalance ? ` ₹ ${userServicebalance}` : `₹ ${0}`}</span>
                                    </div>
                                } value={null} valueRender={() => null} precision={2} />
                            </div>
                        </Header>
                    </div>
                    <div className="plaground-header-description">
                        {(() => {
                            switch (selectedMenu) {
                                case 'chat':
                                    return textToTextheaderComponent;
                                case 'voice':
                                    return textToSpeechHeaderComponent;
                            }
                        })()}
                    </div>
                    <div style={{ display: "flex" }}>
                        <Content
                            style={{
                                margin: "24px 16px",
                                padding: 24,
                                minHeight: 280,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                                width: '70%'
                            }}
                        >
                            <ModelContext.Provider value={{ modelName, selectedModelName, selectedLanguage, outputLengthRange, temperatureRange, topPRange, topKRange, repetitionPenaltyRange, selectedVoice, systemPrompt }}>
                                <PlaygroundRouters />
                            </ModelContext.Provider>
                        </Content>
                        <Content style={{
                            margin: "24px 16px",
                            padding: 24,
                            minHeight: 280,
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                            width: '25%'
                        }}>
                            <ModelContext.Provider value={{ modelName, selectedModelName, setselectedModelName, selectedLanguage, setselectedLanguage, selectedMenu, setOutputLengthRange, outputLengthRange, setTemperatureRange, temperatureRange, setTopPRange, topPRange, setTopKRange, topKRange, repetitionPenaltyRange, setRepetitionPenaltyRange, setMaxSeq, maxSeq, setSelectedVoice, selectedVoice, allanguages, systemPrompt, setSystemPrompt, textToSpeechmodel, setTextToSpeechmodelSelected, textToSpeechmodelSelected, speechToTextSelectedModel, setspeechToTextSelectedModel, speechToTextModel, setspeechToTextModel }}>
                                <ModelFilters />
                            </ModelContext.Provider>
                        </Content>
                    </div>
                </Layout>

            </Layout>
            <Outlet />
        </>
    );
}
