import React, { useContext, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import minskyLogo from "../Assets/minskyLogo1.png";
import { LoginContext } from "../ContextApis/LoginContext";
import "../Style/textfield.css";
import { login } from "../slices/auth";
import "./Login.css";
import { ForgetPassword } from "./ForgetPassword";
import { useDispatch,useSelector } from "react-redux";
import SignUp from "./SignUp";

const initialValues = {
  username: "",
  password: "",
};

const validationSchema = Yup.object({
  username: Yup.string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  password: Yup.string()
    .required("Please enter your Password")
    .min(6, "Password must be at least 6 characters"),
});

export const Login = () => {
  const [action, setaction] = useState("Login");
  const {isLoading } = useSelector((state) => state.auth.login);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const {
    setIsAuthenticated,
    setAuthData,
    setUserId,
    setFirstName,
    setLastName,
    setProfileEmail,
    setProfilePhone,
  } = useContext(LoginContext);
  const [passwordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setValues((prevState) => ({
      ...prevState,
      showPassword: !prevState.showPassword,
    }));
  };

  const navigate = useNavigate();

  const sha256Hash = async (data) => {
    const encoder = new TextEncoder();
    const dataUint8 = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedPassword = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return hashedPassword;
  };

  const logHandleSubmit = async (values, { resetForm }) => {
    const { username, password } = values;
  
    const userEmail = username;
    const userPassword = password;
  
    const hashedPassword = await sha256Hash(userPassword);
  
    const requestData = {
      username: userEmail,
      password: hashedPassword,
    };
  
    dispatch(login(requestData))
      .then((res) => {
        if (res.payload?.status === 200) {
          message.success(res.payload.message);
          setIsAuthenticated(res?.payload.is_authenticated);
          setAuthData(res?.payload?.data?.api_key);
          setUserId(res?.data?.user_id);
          setFirstName(res?.data?.first_name);
          setLastName(res?.data?.last_name);
          setProfileEmail(res?.data?.email);
          setProfilePhone(res?.data?.mobile_number);
          navigate("/playground/chat");
        } else {
          message.error(res?.error?.message);
          setIsAuthenticated(false);
          resetForm();
        }
      })
      .catch((err) => {
        console.log("Error : :", err);
      })
  };

  

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className="card login-side-card" style={{ height: "550px",marginTop:"61px" ,width:"103%"}}>
            <div className="card-body centreMinisky">
              <div className="text-center">
                <img src={minskyLogo} className="login-side-logo" alt="Minsky Logo"></img>
                <div>minsky.app</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5" style={{marginTop:"60px"}}>
          <div className="card Login-form" style={{ height: "550px",width:"100%" }}>
            <div className="card-body">
              {action === "Login" ? (
                <>
                  <div className="form-container">
                    <div className="header">
                      <div className="text">{action}</div>
                      <div className="underline"></div>
                    </div>
                    <div className="main-navigation-login">
                      <span className="login-question">Don't have an account? </span>
                      <span
                        className="login-statement"
                        onClick={() => {
                          setaction("Sign Up");
                        }}
                      >
                        Sign Up
                      </span>
                    </div>
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={logHandleSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <div className="form-group">
                            <Field
                              className="form-control"
                              maxLength={255}
                              id="form_name3"
                              placeholder=""
                              type="text"
                              name="username"
                            />
                            <label htmlFor="form_name_login_username">
                              Email<span className="gl-form-asterisk"></span>
                            </label>
                            <ErrorMessage name="username">
                              {(msg) => (
                                <div
                                  className="abhitest"
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: "51px",
                                    zIndex: " 999",
                                    fontSize: "small",
                                  }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>
                          <div className="form-group">
                            <Field
                              className="form-control"
                              maxLength={255}
                              id="form_name3"
                              placeholder=""
                              style={{ paddingRight: "40px" }}
                              type={values.showPassword ? "text" : "password"}
                              size={50}
                              name="password"
                            />
                            <label htmlFor="form_name_login_passoword">
                              Password<span className="gl-form-asterisk"></span>
                            </label>
                            <i
                              htmlFor="form_name_login_passoword"
                              className="eye-icon"
                              onClick={togglePasswordVisiblity}
                            >
                              {passwordShown ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )}
                            </i>{" "}
                            <ErrorMessage name="password">
                              {(msg) => (
                                <div
                                  className="abhitest"
                                  style={{
                                    color: "red",
                                    position: "absolute",
                                    top: "55px",
                                    zIndex: " 999",
                                    fontSize: "small",
                                  }}
                                >
                                  {msg}
                                </div>
                              )}
                            </ErrorMessage>
                          </div>

                          <ForgetPassword />
                          <div className="submit-container">
                            <button
                              type="submit"
                              className={
                                action === "Sign Up" ? "submit gray" : "submit"
                              }
                              disabled={isLoading}
                              // disabled={
                              //   !(formik.isValid && formik.dirty) ? true : false
                              // }
                            >
                              {isLoading ? (
                                <>

                                  {" Login"}
                                  {"      "} 
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </> )
                              
                              : ("Login" )}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </>
              ) : (
                <SignUp loginStatus={action} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
