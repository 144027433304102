import React, { useState, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { message, Button } from 'antd';
import { LoginContext } from '../ContextApis/LoginContext';
import { updateRecord } from '../slices/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import './Profile.css';

export const RecordUpdate = () => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const {  userId, AuthData } = useContext(LoginContext);
  const { data } = useSelector((state) => state.auth.login);
  const [loading, setLoading] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState(data?.first_name || ''); 
  const [errors, setErrors] = useState({ first_name: '' });

  const handleFirstNameChange = (event) => {
    const value = event.target.value;
  
    if (!/^[A-Za-z\s]*$/.test(value)) {
      setErrors({ first_name: 'Only alphabets and spaces are allowed in the first name.' });
    } else {
      setErrors({ first_name: '' }); 
      setFirstNameValue(value); 
    }
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidated(true);

    if (!errors.first_name) { // Only proceed if there are no errors
      setLoading(true);

      const requestBody = {
        user_id: userId,
        first_name: firstNameValue, // Use firstNameValue instead of firstName
      };

      const headers = {
        'Content-Type': 'application/json',
        'api-key': AuthData,
      };

      dispatch(updateRecord({ requestBody, headers }))
        .then((response) => {
          console.log(response);
          message.success('Profile Updated');
        })
        .catch((error) => {
          if (error.payload && error.payload.detail) {
            message.error(error.payload.detail);
          } else {
            message.error('Something went wrong! Contact Minsky Support Team');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <div style={{ marginBottom: '20px' }}>
        <span style={{ fontSize: '20px' }}>Update Profile</span>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '48%' }}>
          <div className="form-group">
            <input
              id="signup_first_name"
              name="first_name"
              className="form-control"
              value={firstNameValue} // Use firstNameValue instead of firstName
              type="text"
              placeholder=""
              required
              pattern="[A-Za-z]+"
              onChange={handleFirstNameChange}
            />
            <label htmlFor="Signup_first_name">
              First Name<span className="gl-form-asterisk"></span>
            </label>
            {errors.first_name && (
              <span className="log-error-message">{errors.first_name}</span>
            )}
          </div>
        </div>
        <div style={{ width: '48%' }}>
          <div className="form-group">
            <input
              className="form-control"
              name="last_name"
              type="text"
              placeholder=""
              value={data?.last_name}
              required
              readOnly
            />
            <label htmlFor="Signup_first_name">Last Name</label>
          </div>
        </div>
      </div>

      <div className="form-group">
        <input
          name="email"
          className="form-control"
          type="email"
          value={data?.email}
          required
          readOnly
        />
        <label>Email</label>
      </div>

      <div className="form-group">
        <input
          id="signup_mobile"
          name="mobile_number"
          className="form-control"
          type="text"
          placeholder=""
          value={data?.mobile_number}
          required
          readOnly
        />
        <label htmlFor="Signup_mobile">Mobile</label>
      </div>
      <div className="btn-submit">
        <Button type="primary" htmlType="submit" loading={loading} className="btn-submit">
          Update Profile
        </Button>
      </div>
    </Form>
  );
};
