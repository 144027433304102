import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {
  texttospeech:{
    isLoading: false,
  }

};



export const textToSpeech = createAsyncThunk(
  "speech/textToSpeech",
  async ({ requestBody, headers }) => {
    const response = await axiosInstance
      .post(`${MINSKY_URL.textToSpeech}`, requestBody, { headers })
      .catch((error) => {
        return error.response;
      });
    return response.data;
  }
);


export const textTotext = createAsyncThunk(
    "speech/textTotext",
    async ({ requestBody, headers }) => {
      const response = await axiosInstance
        .post(`${MINSKY_URL.textToText}`, requestBody, { headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

 //--------------------- For Speech To Text : Sending the Audio --------------------------
  export const sendingAudioSpeechToText = createAsyncThunk(
    "speech/sendingAudioSpeechToText",
    async ({ requestBody, headers }) => {
      const response = await axiosInstance
        .post(`${MINSKY_URL.speechToText}`, requestBody, { headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );


 //--------------------- For Speech To Text : Custom Audio (Dragged from PC) --------------------------
  export const customAudioSpeechToText = createAsyncThunk(
    "speech/customAudioSpeechToText",
    async ({ requestBody, headers }) => {
      const response = await axiosInstance
        .post(`${MINSKY_URL.speechToText}`, requestBody, { headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

export const speechSlice = createSlice({
  name: "speech",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(textToSpeech.pending, (state, action) => {
      state.status = "pending";
      state.texttospeech.isLoading = true; 
    })
    .addCase(textToSpeech.fulfilled, (state, action) => {
      state.texttospeech.isLoading = false; 

    })
    .addCase(textToSpeech.rejected, (state, action) => {
      state.status = "failed";
      state.isLoading = false; 
    })
  
    //-----------------------------------------------
    .addCase(textTotext.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(textTotext.fulfilled, (state, action) => {
  
      })
      .addCase(textTotext.rejected, (state, action) => {
        state.status = "failed";
      })

      //-----------------------------------------------
    .addCase(customAudioSpeechToText.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(customAudioSpeechToText.fulfilled, (state, action) => {
  
      })
      .addCase(customAudioSpeechToText.rejected, (state, action) => {
        state.status = "failed";
      })

    //-----------------------------------------------
    .addCase(sendingAudioSpeechToText.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(sendingAudioSpeechToText.fulfilled, (state, action) => {
  
      })
      .addCase(sendingAudioSpeechToText.rejected, (state, action) => {
        state.status = "failed";
      })
  },
});

// export const {} = speechSlice.actions;
export const speechReducer = speechSlice.reducer;
