import React from 'react'
import { Table } from 'antd';
import './TableComponent.css'
import moment from 'moment';

export const TexttoSpeech = (data) => {

    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'blue-column',
            render: (text) => moment(text).format('YYYY-MM-DD')
        },
        {
            title: 'Characters(numbers)',
            dataIndex: 'tts_char',
            key: 'tts_char',
            width: 350,
            className: 'blue-column'
        },
        {
            title: 'IP Address',
            dataIndex: 'ip_address',
            key: 'ip_address',
            className: 'blue-column'
        },
        {
            title: 'Amount Deducted',
            dataIndex: 'amount_deducted',
            className: 'blue-column'
        },
    ];
    return <Table dataSource={data.value} columns={columns} />;
}