import { Button, Input, message, Tooltip } from 'antd';
import React, { useContext, useState, useEffect } from 'react'
import { ModelContext } from './ContextApis/ModelContext';
import { RightOutlined, ReloadOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'clipboard-copy';
import './ChatToText.css'
import { textTotext } from './slices/speechSlice';
import { useDispatch } from "react-redux";
import {useSelector} from "react-redux";


export const ChatToText = ({ messages }) => {
    const dispatch = useDispatch();
    const {data } = useSelector((state) => state.auth.login);

    const [messageData, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [loading, setLoading] = useState(false);
    const { selectedModelName, outputLengthRange, temperatureRange, topPRange, topKRange, repetitionPenaltyRange, systemPrompt } = useContext(ModelContext)

    const handleCopyClick = (code) => {
        copy(code);
        message.success("Code copied!")
    };
    const components = {
        code: ({ node, inline, className, children, ...props }) => {
            return (
                <div className='main-code-block'>
                    <CopyOutlined className='main-code-block-copy' onClick={() => handleCopyClick(children)} />
                    <code>{children}</code>
                </div>
            );
        },
    };
    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            sendMessage();
        }
    }
    const handleSearchClick = () => {
        sendMessage()
    };
    const resetChat = () => {
        setChat([])
    }

    useEffect(() => {
        setChat([]);
    }, [selectedModelName]);


    const sendMessage = async () => {
        setLoading(true);
        if (messageData.trim() !== '') {
          setChat([...chat, { text: messageData, isHtml: false }]);
          setMessage('');
      
          const requestBody = {
            "model": selectedModelName,
            "query": `<s>[INST] <<SYS>>${systemPrompt}<</SYS>>${messageData}[/INST]`,
            "max_tokens": outputLengthRange[0],
            "temperature": temperatureRange[0],
            "top_p": topPRange[0],
            "top_k": topKRange[0],
            "repetition_penalty": repetitionPenaltyRange[0],
          };
      
          const headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'api-key': data?.api_key ? data.api_key : ""
          };
      
          dispatch(textTotext({ requestBody, headers}))
            .then((response) => {
              console.log(response)
              setChat((prevChat) => [...prevChat, { text: response.payload.response[0].text, isHtml: true }]);
              setLoading(false);
            })
            .catch((error) => {
              if (error.payload && error.payload.detail) {
                message.error(error.payload.detail);
              } else {
                message.error("Something went wrong! Contact Minsky Support Team");
              }
              setLoading(false);
            });
        } 
          setLoading(false);
        
      };

      



    return (
        <div className='main-chat-container'>
            <div className='chat-container'>
                {chat.map((msg, index) => (
                    <div key={index} className='chat-response' style={{ textAlign: msg.isHtml ? 'left' : 'right' }}>
                        {msg.isHtml ? (
                            <ReactMarkdown className='animated-message' components={components} >{msg.text}</ReactMarkdown>
                        ) : (
                            <span className='animated-message' id='chat-request'>
                                {msg.text}
                            </span>
                        )}
                    </div>
                ))}
                {loading && (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                )}
            </div>
            <div className='chatbox-container'>
                <Input
                    className='Chat-text-field'
                    type="text"
                    value={messageData}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type text here ..."
                    style={{ fontSize: '15px' }}
                    onKeyDown={handleKeyDown}
                    suffix={
                        <Tooltip title="Send">
                            <RightOutlined
                                className='chat-send-tooltip'
                                onClick={handleSearchClick}
                            />
                        </Tooltip>
                    }
                />
                <Button onClick={resetChat} className='chat-box-reset'><Tooltip title="Reset Chat">
                    <ReloadOutlined style={{ color: '#1890ff' }} />
                </Tooltip></Button>
            </div>
        </div>

    )
}
