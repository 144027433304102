import React, { useState, useContext } from 'react'
import { Form } from 'react-bootstrap';
import { message, Button } from 'antd';
import { LoginContext } from '../ContextApis/LoginContext';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import './Profile.css';
import { useDispatch } from "react-redux";
import {changePassword} from "../slices/profileSlice"

export const ChangePassword = () => {
  const dispatch = useDispatch();
  const [validated] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [oldpasswordShown, setOldPasswordShown] = useState(false);
  const {AuthData,setIsAuthenticated } = useContext(LoginContext);
  const [loading, setloading] = useState(false)
  const [errors, setErrors] = useState({
    old_password: '',
    password: '',
    confirm_password: '',
  });


  

  const sha256Hash = async (data) => {
    const encoder = new TextEncoder();
    const dataUint8 = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest('SHA-256', dataUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedPassword = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
    return hashedPassword;
  };

  const handleOldPasswordChange = (event) => {
    const value = event.target.value;

    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        old_password: "Old password cannot be empty.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        old_password: "",
      }));
    }

    setOldPassword(value);
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;

    if (value.length > 0 && value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "New password cannot be empty.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "",
      }));
    }

    setPassword(value);
  };

  const handleConfirmPasswordChange = (event) => {
    const value = event.target.value;

    if (value.trim() === '') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm_password: "Confirm password cannot be empty.",
      }));
    } else if (value !== password) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm_password: "Passwords do not match.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirm_password: "",
      }));
    }

    setConfirmPassword(value);
  };

  const handleAPICall = async (event) => {
    event.preventDefault();
    setloading(true);
  
    const requestBody = {
      password: await sha256Hash(oldPassword),
      new_password: await sha256Hash(password)
    };
  
    const headers = {
      'Content-Type': 'application/json',
      'api-key': AuthData
    };

  




  
    dispatch(changePassword({ requestBody, headers }))
      .then((response) => {

        const customMessage = "! Please login again.";
        const payloadMessage = response.payload.message;
        const combinedMessage = payloadMessage + customMessage;
        message.success(combinedMessage);
        setIsAuthenticated(false);
        sessionStorage.clear();
        setloading(false);
      })
      .catch((error) => {
 
        if (error.payload && error.payload.detail) {
          message.error(error.payload.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
        setloading(false);
      });
  
    // Reset input fields and errors
    setOldPassword('');
    setPassword('');
    setConfirmPassword('');
    setErrors({
      old_password: '',
      password: '',
      confirm_password: '',
    });
  };


  const handleFormSubmit = (event) => {
    if (!errors.password && !errors.confirm_password) {
      handleAPICall(event);
    }
  };


  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleoldPasswordVisibility = () => {
    setOldPasswordShown(!oldpasswordShown);
  };

  return (
    <>
      <Form validated={validated} onSubmit={handleFormSubmit}>
        <div className="form-group">
          <input
            className='form-control'
            name="oldpassword"
            placeholder=""
            type={oldpasswordShown ? "text" : "password"}
            style={{ paddingRight: "40px" }}
            onChange={handleOldPasswordChange}
            value={oldPassword}
            required
          />
          <label htmlFor="profile_oldpassoword">Old Password<span className="gl-form-asterisk"></span></label>
          <i htmlFor="profile_oldpassoword" className="eye-icon" onClick={toggleoldPasswordVisibility}>{oldpasswordShown ? <EyeTwoTone /> : <EyeInvisibleOutlined />}</i>
          {errors.old_password && (
            <span className="log-error-message">
              {errors.old_password}
            </span>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: 'space-between' }}>
          <div style={{ width: '48%' }}>
            <div className="form-group">
              <input
                className='form-control'
                name="newpassword"
                placeholder=""
                value={password}
                type={passwordShown ? "text" : "password"}
                style={{ paddingRight: "40px" }}
                onChange={handlePasswordChange}
                required
              />
              <label htmlFor="profile_newpassoword">New Password<span className="gl-form-asterisk"></span></label>
              <i htmlFor="profile_newpassoword" className="eye-icon" onClick={togglePasswordVisibility}>{passwordShown ? <EyeTwoTone /> : <EyeInvisibleOutlined />}</i>
              {errors.password && (
                <span className="log-error-message">
                  {errors.password}
                </span>
              )}
            </div>
          </div>
          <div style={{ width: '48%' }}>
            <div className="form-group">
              <input
                id="confirmPassword"
                className='form-control'
                name="confirm_password"
                placeholder=""
                type={passwordShown ? "text" : "password"}
                style={{ paddingRight: "40px" }}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                required
              />
              <label htmlFor="changeconfirmpassoword">Confirm Password<span className="gl-form-asterisk"></span></label>
              <i className="eye-icon" onClick={togglePasswordVisibility}>{passwordShown ? <EyeTwoTone /> : <EyeInvisibleOutlined />}</i>
              {errors.confirm_password && (
                <span className="log-error-message">
                  {errors.confirm_password}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='btn-submit'>
          <Button type="primary" htmlType='submit' loading={loading} > Change Password
          </Button>
        </div>
      </Form>
    </>
  )
}
