import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {
  api_key: "",
  status: "",
};

export const generateKey = createAsyncThunk(
  "updateKey/generateKey",
  async ({ headers }) => {
    try {
      const response = await axiosInstance.get(`${MINSKY_URL.generateAPIKey}`, {
        headers,
      });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const generateKeySlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateKey.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(generateKey.fulfilled, (state, action) => {
        
      })
      .addCase(generateKey.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

// export const {} = generateKeySlice.actions;
export const generateKeyReducer = generateKeySlice.reducer;
