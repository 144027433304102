import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {

};


export const userBalanceUpdate = createAsyncThunk(
    "updateWallet/userBalanceUpdate",
    async ({headers}) => {
      const response = await axiosInstance
        .get(
          `${MINSKY_URL.userBalance}`, {headers}
        )
        .catch((error) => {
          return error.response;
        });
  
      return response.data;
    }
  );
  

  export const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(userBalanceUpdate.pending, (state, action) => {
          state.status = "pending";
        })
        .addCase(userBalanceUpdate.fulfilled, (state, action) => {
  
        })
        .addCase(userBalanceUpdate.rejected, (state, action) => {
          state.status = "failed";
        })

    },
  });
  
  // export const {} = walletSlice.actions;
  export const walletReducer = walletSlice.reducer;