import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../Utilities/axiosInstance";
import MINSKY_URL from "../config";

const initialState = {

};


export const textToTextModelNames = createAsyncThunk(
    "modelNames/textToTextModelNames",
    async ({headers}) => {
      const response = await axiosInstance
        .get(`${MINSKY_URL.textToTextModel}`,{ headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

  export const textToSpeechLangNames = createAsyncThunk(
    "languageNames/textToSpeechLangNames",
    async ({headers}) => {
      const response = await axiosInstance
        .get(`${MINSKY_URL.textToSpeechLanguage}`,{ headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

  export const speechToTextModelNames = createAsyncThunk(
    "modelNames/speechToTextModelNames",
    async ({headers}) => {
      const response = await axiosInstance
        .get(`${MINSKY_URL.speechToTextModel}`,{ headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

  export const textToSpeechModelNames = createAsyncThunk(
    "modelNames/speechToTextModelNames",
    async ({headers}) => {
      const response = await axiosInstance
        .get(`${MINSKY_URL.textToSpeechModel}`,{ headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );



export const promptOptions = createAsyncThunk(
    "options/promptOptions",
    async ({headers}) => {
      const response = await axiosInstance
        .get(`${MINSKY_URL.getPrompt}`,{ headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );


export const createPrompt = createAsyncThunk(
    "prompt/createPrompt",
    async ({ requestBody, headers }) => {
      const response = await axiosInstance
        .post(`${MINSKY_URL.createPrompt}`, requestBody, { headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

  export const updatePrompt = createAsyncThunk(
    "prompt/updatePrompt",
    async ({ requestBody, headers }) => {
      const response = await axiosInstance
        .post(`${MINSKY_URL.updatePrompt}`, requestBody, { headers })
        .catch((error) => {
          return error.response;
        });
      return response.data;
    }
  );

  export const deletePrompt = createAsyncThunk(
    "prompt/deletePrompt",
    async ({prompt,headers}) => {
        const response = await axiosInstance.delete(
          `${MINSKY_URL.deletePrompt}?prompt=${prompt}`,
          { headers })
      .catch((error) => {
        return error.response;
      });
  
      return response.data;
    }
  );



  

  export const modelSlice = createSlice({
    name: "model",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(createPrompt.pending, (state, action) => {
          state.status = "pending";
        })
        .addCase(createPrompt.fulfilled, (state, action) => {
  
        })
        .addCase(createPrompt.rejected, (state, action) => {
          state.status = "failed";
        })

        //---------------------------------------------------------------------
        builder
        .addCase(updatePrompt.pending, (state, action) => {
          state.status = "pending";
        })
        .addCase(updatePrompt.fulfilled, (state, action) => {
  
        })
        .addCase(updatePrompt.rejected, (state, action) => {
          state.status = "failed";
        })

        //----------------------------------------------------------------------
        .addCase(deletePrompt.pending, (state, action) => {
          state.status = "pending";
        })
        .addCase(deletePrompt.fulfilled, (state, action) => {
  
        })
        .addCase(deletePrompt.rejected, (state, action) => {
          state.status = "failed";
        });


    },
  });
  
  // export const {} = modelSlice.actions;
  export const modelReducer = modelSlice.reducer;