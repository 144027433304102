import React, { useState } from 'react'
import { Input, Button, Modal, Alert } from 'antd';
import './Payment.css'
import { Checkout } from "./Checkout";

export const Home = () => {
  const [paymentTotalAmount, setPaymentTotalAmount] = useState(500)
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState('');
  // const navigate = useNavigate();

  const handleButtonClick = () => {
    // Show the modal on button click
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleAmtChange = (e) => {
    const enteredAmount = Number(e.target.value);
    setPaymentTotalAmount(enteredAmount);
    if (enteredAmount < 100) {
      setError('Amount must be at least ₹100');
    } else {
      setError('');
      setPaymentTotalAmount(enteredAmount);
    }
  };

  const handleIncrement = (amountToAdd) => {
    // Increment the amount by the specified value
    const incrementedAmount = parseInt(paymentTotalAmount, 10) + amountToAdd;

    // Check if the resulting amount is greater than or equal to 100
    if (incrementedAmount >= 100) {
      setPaymentTotalAmount(incrementedAmount.toString());
      setError('');
    } else {
      setError('Amount must be at least ₹100');
    }
  };


  return (
    <div className="main-payment">
      <div class="payment-header">
        <span className="payment-heading" >Add Amount</span>
        <Input prefix="₹" name="add_amount" type="text" placeholder="" required value={paymentTotalAmount} onChange={handleAmtChange} />
        {error && <Alert message={error} type="error" showIcon />}
      </div>
      <div className="payment-add-button">
        <Button type="primary" ghost onClick={() => handleIncrement(1000)}>+1000</Button>
        <Button type="primary" ghost onClick={() => handleIncrement(2000)} style={{ marginLeft: '10px' }}>+2000</Button>
        <Button type="primary" ghost onClick={() => handleIncrement(10000)} style={{ marginLeft: '10px' }}>+10000</Button>
      </div>
      <div className="payment-make-payment-button">
        <Button type="primary" style={{ width: '500px' }} onClick={handleButtonClick} disabled={error ? true : false}>Make Payment</Button>
      </div >
      <Modal
        title="Checkout"
        open={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        destroyOnClose
        centered
      >
        <Checkout amount={paymentTotalAmount} />
      </Modal>
    </div>
  )
}
