import { configureStore } from "@reduxjs/toolkit";
import { authReducer} from "./slices/auth";
import { profileReducer } from "./slices/profileSlice"
import { generateKeyReducer } from "./slices/generateKeySlice"
import { speechReducer } from "./slices/speechSlice";
import { walletReducer } from "./slices/walletSlice";
import { analyticsReducer } from "./slices/analyticsSlice"
import { modelReducer } from "./slices/modelSlice";


const reducer = {
  auth: authReducer,
  profile:profileReducer,
  generateKey:generateKeyReducer,
  speech:speechReducer,
  wallet: walletReducer,
  analytics:analyticsReducer,
  model:modelReducer
};

const store = configureStore({
  reducer: reducer, 
  devTools: true
  
});

export default store;
