import React, { useState, useEffect, useContext } from "react";
import './Payment.css'
import axios from 'axios'
import { PaymentSuccess } from "./PaymentSuccess";
import { PaymentCancel } from "./PaymentCancel";
import { LoginContext } from '../ContextApis/LoginContext';


const handleFormSubmit = async (e, authData, quantity) => {

    e.preventDefault();
    // Make your Axios POST call here
    await axios.get(`https://uat.minsky.app/checkout/pg/send-request/?quantity=${quantity.quantity}`, {
        headers: {
            'api-key': authData.auth
        }
    })
        .then((response) => {
            console.log(response.data.checkout_url)

            window.location.href = response.data.checkout_url;
        })
        .catch((error) => {
            console.error("Error making API calls:", error.message);
        });
};

const ProductDisplay = (props) => (

    <section>
        {console.log(props)}
        <div className="product">
            <img
                src="https://i.imgur.com/EHyR2nP.png"
                alt="The cover of Attachments"
                className="product-image"
            />
            <div className="description">
                <h3>Neurobridge Tech</h3>
                <h5>₹ {props.amt.amount}</h5>
            </div>
        </div>
        <form onSubmit={(e) => handleFormSubmit(e, { auth: props.auth }, { quantity: props.amt.amount })}>
            <button type="submit" className="checkout-button">
                Checkout
            </button>
        </form>
    </section>
);

export const Checkout = (props) => {
    const [message, setMessage] = useState("");
    const [paymentStatus, setpaymentStatus] = useState()
    const { AuthData } = useContext(LoginContext);

    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
            setpaymentStatus("success")
            setMessage("Order placed! You will receive an email confirmation.");
        }

        if (query.get("canceled")) {
            setpaymentStatus("canceled")
            setMessage(
                "Order canceled -- continue to shop around and checkout when you're ready."
            );
        }
    }, []);
    return message ? (
        paymentStatus === 'success' ? <PaymentSuccess /> : <PaymentCancel />
    ) : (
        <ProductDisplay amt={props} auth={AuthData} />
    );
}
