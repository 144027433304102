import "./App.css";
import Login from "./Authentication/Login";
import { useState, React, useEffect } from "react";
import { LayoutDashboard } from "./Layouts/LayoutDashboard";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginContext } from "./ContextApis/LoginContext";
import { LayoutPlayground } from "./Layouts/LayoutPlayground";
import { UpdatePassword } from "./Authentication/UpdatePassword";
import { useSelector ,useDispatch} from "react-redux";
import { userBalanceUpdate } from "./slices/walletSlice";

function App() {
  const dispatch = useDispatch();
  // const state = useSelector((state) => state?.auth?.login ?? {});
  const { is_authenticated, data } = useSelector((state) => state.auth.login);

  

  const [AuthData, setAuthData] = useState(data?.api_key ? data.api_key : "");
  const [firstName, setFirstName] = useState(
    data?.first_name ? data.first_name : ""
  );
  const [lastName, setLastName] = useState(data?.lastName ? data.lastName : "");
  const [profileEmail, setProfileEmail] = useState(
    data?.email ? data.email : ""
  );
  const [profilePhone, setProfilePhone] = useState(
    data?.mobile_number ? data.mobile_number : ""
  );
  const [userId, setUserId] = useState(data?.user_id ? data.user_id : "");

  const [isAuthenticated, setIsAuthenticated] = useState(
    is_authenticated?.toString() ? is_authenticated.toString() : false
  );

  const [userServicebalance, setUserServicebalance] = useState(0);
  useEffect(() => {
    // Check authentication status on initial load
    const storedAuthentication = is_authenticated.toString() === "true";
    setIsAuthenticated(storedAuthentication);
  }, []);



  const headers = {
      "Content-Type": "application/json",
      "api-key": AuthData
  };


  const updateUserBalance = async () => {
    if (isAuthenticated) {
      dispatch(userBalanceUpdate({headers}))
        .then((res) => {
          setUserServicebalance(res.payload.user_balance);
        })
        .catch((err) => {
          console.log("Error: ", err);
        });
    }
};


  useEffect(() => {
    // Check authentication status on initial load
    const storedAuthentication = is_authenticated.toString() === "true";
    setIsAuthenticated(storedAuthentication);
  }, []);

  // this api is rendered after every 5 min

  useEffect(() => {
    if (isAuthenticated) {
      updateUserBalance();
      const apiCallInterval = setInterval(() => {
        updateUserBalance();
      }, 300000);
      return () => clearInterval(apiCallInterval);
    }
  }, [isAuthenticated]);

  return (
    <div className="backColor">
      <LoginContext.Provider
        value={{
          isAuthenticated,
          setIsAuthenticated,
          AuthData,
          setAuthData,
          userId,
          setUserId,
          firstName,
          profilePhone,
          lastName,
          profileEmail,
          setFirstName,
          setLastName,
          setProfileEmail,
          setProfilePhone,
          userServicebalance,
        }}
      >
        <Routes>
          <Route path="/update/password/:key" element={<UpdatePassword />} />
          {!isAuthenticated ? (
            <>
              <Route path="/" element={<Login />} />
              {/* <Route path="/signup" element={<SignUp />} /> */}
              <Route path="/*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/" element={<LayoutPlayground />} />
              <Route
                path="/playground/*"
                element={<LayoutPlayground />}
              ></Route>
              <Route path="/dashboard/*" element={<LayoutDashboard />}></Route>
            </>
          )}
        </Routes>
      </LoginContext.Provider>
    </div>
  );
}

export default App;
