import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useParams } from 'react-router-dom';
import minskyLogo from '../Assets/minskyLogo1.png'

import axios from "axios";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export const UpdatePassword = () => {
    const [logerrors, setLogErrors] = useState({});
    const [passwordShown, setPasswordShown] = useState(false);
    const { key } = useParams();
    const [passwordKey, setPasswordKey] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        // Use the 'key' obtained from the URL
        setPasswordKey(key);
    }, [key]);

    const sha256Hash = async (data) => {
        const encoder = new TextEncoder();
        const dataUint8 = encoder.encode(data);
        const hashBuffer = await crypto.subtle.digest('SHA-256', dataUint8);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashedPassword = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
        return hashedPassword;
    };
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const [logFormData, setLogFormData] = useState({
        new_password: "",
        hash_password: "",
    });
    const logHandleChange = (e) => {
        const { name, value } = e.target;
        setLogFormData({
            ...logFormData,
            [name]: value,
        });
    };

    const logHandleSubmit = async (e) => {
        const logValidationErrors = {};
        e.preventDefault();

        if (logFormData.hash_password !== logFormData.new_password) {
            logValidationErrors.hash_password = "Password do not match";
        }
        else if (logFormData.hash_password.length < 6) {
            logValidationErrors.hash_password = "Password should be at least 6 characters";
        } else if (logFormData.new_password.length < 6) {
            logValidationErrors.new_password = "Password should be at least 6 characters";
        }
        setLogErrors(logValidationErrors);

        if (Object.keys(logValidationErrors).length === 0) {
            const hashedPassword = await sha256Hash(logFormData.hash_password);
            const requestData = {
                hash_password: hashedPassword,
                key: passwordKey ? passwordKey : null
            };
            axios
                .post("https://uat.minsky.app/apis/user/verify/forgot/password/", requestData)
                .then((data) => {
                    console.log(data)
                    message.success(data.data.message)
                    setPasswordKey("")
                    navigate("/");
                })
                .catch((err) => {
                    message.error(err.response.data.detail);
                    return null;
                });
            e.target.reset();
        }
    };
    return (
        <div className="main-login-container">
            <div className="login-side-card">
                <div>
                    <img src={minskyLogo} alt="minisky" className="login-side-logo"></img>
                </div>
                <div style={{ marginTop: '200px' }}>
                    minsky.app
                </div>
            </div>
            <div className="Login-form">
                <form onSubmit={logHandleSubmit}>
                    <div className="form-container">
                        <div className="header">
                            <div className="text" style={{ fontSize: '30px', marginBottom: '2px' }}>Update Password</div>
                            <div className="underline" style={{ marginBottom: '20px' }}></div>
                        </div>
                        <div className="">
                            <div className="form-group">
                                <input id="new_password1" name="new_password" className="form-control" type={passwordShown ? "text" : "password"} placeholder="" required onChange={logHandleChange} />
                                <label htmlFor="form_name_new_password">New Password<span className="gl-form-asterisk"></span></label>
                                <i htmlFor="form_name_new_password" className="eye-icon" onClick={togglePasswordVisiblity}>{passwordShown ? <EyeTwoTone /> : <EyeInvisibleOutlined />}</i>{" "}
                                {logerrors.new_password && (
                                    <span className="log-error-message">{logerrors.new_password}</span>
                                )}
                            </div>
                            <div className="form-group">
                                <input
                                    id="form_name3"
                                    className='form-control'
                                    name="hash_password"
                                    placeholder=""
                                    type={passwordShown ? "text" : "password"}
                                    style={{ paddingRight: "40px" }}
                                    onChange={logHandleChange}
                                    required
                                />
                                <label htmlFor="form_name_confirm_password">Confirm New Password<span className="gl-form-asterisk"></span></label>
                                <i htmlFor="form_name_confirm_password" className="eye-icon" onClick={togglePasswordVisiblity}>{passwordShown ? <EyeTwoTone /> : <EyeInvisibleOutlined />}</i>{" "}
                                {logerrors.hash_password && (
                                    <span className="log-error-message">
                                        {logerrors.hash_password}
                                    </span>
                                )}
                            </div>

                            <div className="submit-container">
                                <button
                                    type="submit"
                                    className="submit"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
