import React, { useContext } from 'react'
import { Select } from 'antd';
import { ModelContext } from '../ContextApis/ModelContext';
import { Radio } from 'antd';

export const Modifications = () => {
    const { selectedVoice, setSelectedVoice, setselectedLanguage, selectedLanguage, allanguages, textToSpeechmodel, setTextToSpeechmodelSelected, textToSpeechmodelSelected } = useContext(ModelContext)

    const handleSelectModelChange = (value) => {
        setTextToSpeechmodelSelected(value);
    };
    // const showModal = () => {
    //     setIsModalOpen(true);
    // };
    // const handleOk = () => {
    //     setIsModalOpen(false);
    // };
    // const handleCancel = () => {
    //     setIsModalOpen(false);
    // };

    const handleLanguageChange = (value, option) => {
        setselectedLanguage(option.label);
    };

    const handleVoiceChange = (e) => {
        setSelectedVoice(e.target.value);
    };
    const languageOptions = allanguages && allanguages.map((language) => ({
        value: language,
        label: language,
    }));

    const mappedOptions = textToSpeechmodel && textToSpeechmodel.map((item) => ({
        value: item,
        label: item,
    }));
    return (
        <div>
            <div className='model-heading'>Model</div>
            <div className='model-dropdown'>
                <Select placeholder="Select a Model" optionFilterProp="children" options={mappedOptions} value={textToSpeechmodelSelected} onChange={handleSelectModelChange} style={{ width: '250px' }}></Select>
            </div>
            <div className='modification-container'>
                <div className='main-modification'>
                    <span className='modification-title'>Modification</span>
                </div>
                <div style={{ marginTop: '10px' }}>
                    <span className='language-title'>Language</span>
                </div>
                <div className='language-dropdown'>
                    <Select
                        showSearch
                        style={{
                            width: 200,
                        }}
                        placeholder="Search to Select"
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        options={languageOptions}
                    />
                </div>
                <div style={{ marginTop: '20px' }}>
                    <span className='voice-title'>Voice</span>
                    <div className='main-voice-container'>
                        <Radio.Group onChange={handleVoiceChange} value={selectedVoice}>
                            <Radio value={'male'}>Male</Radio>
                            <Radio value={'female'}>Female</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        </div>
    )
}
