import React, { useContext, useState } from 'react'
import { InboxOutlined } from '@ant-design/icons';
import { LoginContext } from './ContextApis/LoginContext';
import { message, Upload } from 'antd';
import { AudioRecorder } from 'react-audio-voice-recorder';
import TextArea from 'antd/es/input/TextArea';
import './Style/Mic.css'
import { useDispatch } from "react-redux";
import { sendingAudioSpeechToText } from './slices/speechSlice';
import { customAudioSpeechToText } from './slices/speechSlice';

export const SpeechToText = () => {
    const dispatch = useDispatch();
    const { Dragger } = Upload;
    const [audioResult, setAudioResult] = useState(null)
    const { AuthData } = useContext(LoginContext);
    const checkFileType = (file) => {
        const acceptedTypes = ['audio/mp3', 'audio/wav', 'audio/ogg', 'audio/mpeg'];
        const isValidType = acceptedTypes.includes(file.type);

        if (!isValidType) {
            message.error('You can only upload audio files (MP3, WAV, OGG).');
        }

        return isValidType;
    };
    

    const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'api-key': AuthData
        }
      };


      const success = () => {
        const loadingMessage = message.loading('Please wait processing...', 0); // Display loading message
        return loadingMessage;
      };
    
    
    


    const customRequest = ({ file, onSuccess, onError }) => {
         
      const isValidType = checkFileType(file);
      if (!isValidType) {
          onError('Invalid file type');
          return;
      }
        
      
          const formData = new FormData();
          formData.append('audio_file', file);
      
          dispatch(customAudioSpeechToText.pending());
          
          const loadingMessage = success(); 


      
           dispatch(customAudioSpeechToText({ requestBody: formData, headers: config.headers }))
            .then((response) => {
              setAudioResult(JSON.stringify(response.payload?.text));
              onSuccess(response.payload);
              loadingMessage(); // Closing the loading message

              return response.payload; 
            })
            .catch((error) => {
              console.error('Error sending audio data to API:', error);
              onError(error);
              throw error;
            });
        
      };


    const sendAudioDataToAPI = (audioBlob) => {
        
        const formData = new FormData();
        // console.log(audioBlob)
        formData.append('audio_file', audioBlob, 'recording.wav');
        
      
        dispatch(sendingAudioSpeechToText({ requestBody:formData, headers: config.headers }))
          .then((response) => {
            // console.log(response)
            setAudioResult(JSON.stringify(response.payload?.text));
          })
          .catch((error) => {
            console.error('Error sending audio data to API:', error);
          });
      };
      

 

    return (
        <div className='main-speech-text-conatiner'>
            <div className='audio-file-drag'>
                <Dragger customRequest={customRequest}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag audio file to this area to upload</p>
                    <p className="ant-upload-hint">
                    </p>
                </Dragger>
            </div>
            <div style={{ marginBottom: '15px' }}>

                <AudioRecorder
                    onRecordingComplete={sendAudioDataToAPI}
                    audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                    }}
                    onNotAllowedOrFound={(err) => console.table(err)}
                    // downloadOnSavePress={true}
                    // downloadFileExtension="wav"
                    mediaRecorderOptions={{
                        audioBitsPerSecond: 128000,
                    }}
                />
            </div>
            <div>
                <TextArea placeholder='Text will be displayed here' style={{ height: '30vh' }} value={audioResult} readOnly>{audioResult}</TextArea>
            </div>
        </div>
    )
}
