import React from 'react'
import { Button, Result } from 'antd';

export const PaymentCancel = () => {
    return (
        <Result
            status="error"
            title="Submission Failed"
            subTitle="Please check and modify the following information before resubmitting."
            extra={[
                <Button key="buy">Try Again</Button>,
            ]}
        />
    )
}
