import React, { useContext } from 'react'
import { Route, Routes, Navigate } from "react-router-dom";
import { ChatToText } from '../ChatToText';
import { SpeechToText } from '../SpeechToText';
import { TexttoSpeech } from '../TexttoSpeech';
import { LoginContext } from "../ContextApis/LoginContext";

export const PlaygroundRouters = () => {
    const { isAuthenticated } = useContext(LoginContext)
    return (
        <div>
            {isAuthenticated ?
                <Routes>
                    <Route path="chat" element={<ChatToText />}></Route>
                    <Route path="transcribe" element={<SpeechToText />}></Route>
                    <Route path="voice" element={<TexttoSpeech />}></Route>
                    <Route path="/*" element={<Navigate to="/playground/chat" />} />
                </Routes> : <></>}
        </div >
    )
}
