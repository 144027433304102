import React, { useContext } from 'react'
import { Modifications } from './Modifications';
import { ModelContext } from '../ContextApis/ModelContext';
import { TextModification } from './TextModification';

export const ModelFilters = () => {
    const { selectedMenu } = useContext(ModelContext)

    let renderComponent;

    if (selectedMenu === 'chat') {
        renderComponent = <TextModification />;
    } else if (selectedMenu === 'transcribe') {
        // renderComponent = <SpeechModification />
    } else {
        renderComponent = <Modifications />
    }
    return (
        <div >
            {renderComponent}
        </div>

    )
}
