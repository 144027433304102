import React, { useState, useContext, useEffect } from "react";
import { Routers } from "../Navigation/Routers";
import MinskyLogo from '../Assets/minskyLogo1.png'
import MinskyShortM from '../Assets/minskyLogoM.png'
import { Outlet, useNavigate } from "react-router-dom";
import { LoginContext } from "../ContextApis/LoginContext";
import { useLocation } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  DashboardOutlined,
  KeyOutlined,
  PoweroffOutlined,
  CreditCardOutlined,
  PlaySquareFilled,
  BookOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, theme, Statistic } from "antd";
const { Header, Sider, Content} = Layout;

export const LayoutDashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState("profile");
  const { userServicebalance, setUserId, setIsAuthenticated, setFirstName, setLastName, setProfileEmail, setProfilePhone, setAuthData } = useContext(LoginContext);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  // const handleOpenChange = (newOpen) => {
  //   setOpen(newOpen);
  // };
  const handleDashboardNavigation = () => {
    navigate('/playground/chat');
  };
  const navigateAPIDoc = () => {
    window.open('https://uat.minsky.app/docs/redoc/', '_blank');
  }
  const handleLogout = () => {
    sessionStorage.clear();
    setIsAuthenticated(false);
    setAuthData(false);
    setFirstName(false);
    setLastName(false);
    setProfileEmail(false);
    setProfilePhone(false);
    setUserId(false);
    navigate("/");
  };

  const handleProfileBalanceClick = () => {
    navigate("/dashboard/billing")
  };
  const handleMenuClick = ({ key }) => {
    setSelectedKey(key);
    navigate(key);
  };
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const key = pathSegments[pathSegments.length - 1];
    setSelectedKey(key || 'profile');
  }, [location]);
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            background: 'black'
          }}
        >
          <div className="demo-logo-vertical" />
          <div className="" style={{ color: "white" }}>
            {" "}
            <div className="comp-logo">
              {collapsed ?
                <img src={MinskyShortM} alt="minisky" style={{ width: 80, height: '3vh' }}></img>
                :
                <img src={MinskyLogo} alt="minisky" style={{ width: 140 }}></img>
              }

            </div>

          </div>
          <Menu
            theme="dark"
            style={{ background: 'black', color: '#a6adb4' }}
            mode="inline"
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
            items={[
              {
                key: "profile",
                icon: <UserOutlined />,
                label: "Profile",
              },
              {
                key: "apikeys",
                icon: <KeyOutlined />,
                label: "API Keys",
              },
              {
                key: "analytics",
                icon: <DashboardOutlined />,
                label: "Analytics",
              },
              {
                key: "billing",
                icon: <CreditCardOutlined />,
                label: "Payment",
              },
            ]}
          />
          <div className={collapsed ? "main-collapsed-siderbar-footer" : "main-siderbar-footer"}>
            <hr style={{ color: 'white' }}></hr>
            <div
              onClick={navigateAPIDoc}
              className="footer_sider_bar"
            >
              {collapsed ? <BookOutlined /> : <><BookOutlined style={{ marginRight: '8px' }} />
                API Docs</>}
            </div>

            <div
              onClick={handleDashboardNavigation}
              className="footer_sider_bar"
            >
              {collapsed ? <PlaySquareFilled /> : <><PlaySquareFilled style={{ marginRight: '8px' }} />
                Playground</>}
            </div>

            <div
              onClick={handleLogout}
              className="footer_sider_bar"
            >
              {collapsed ? <PoweroffOutlined /> : <><PoweroffOutlined style={{ marginRight: '8px' }} />
                Logout</>}
            </div>
          </div>
        </Sider>
        <Layout style={collapsed ? { marginLeft: 80 } : { marginLeft: 200 }}>
          <div>
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
                display: 'flex',
                justifyContent: "space-between",
                alignItems: 'center'
              }}
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <div className="profile-balance" onClick={handleProfileBalanceClick} >
                <Statistic title={
                  <div className="balance-main">
                    <AccountBalanceWalletIcon fontSize="large" style={{ color: '#896ACB' }} />
                    <span className="balance-fontsize">{userServicebalance ? ` ₹ ${userServicebalance}` : `₹ ${0}`}</span>
                  </div>
                } value={null} valueRender={() => null} precision={2} />
              </div>
            </Header>
          </div>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routers />
          </Content>
        </Layout>
      </Layout>
      <Outlet />
    </>
  );
};
