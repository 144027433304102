import React, { useState, useEffect, useContext } from 'react'
import { Select, Modal, Input, Slider, Button, message } from 'antd';
import { PlusCircleFilled, EditOutlined } from '@ant-design/icons';
import { ModelContext } from '../ContextApis/ModelContext';
import { LoginContext } from '../ContextApis/LoginContext';
import { useDispatch} from 'react-redux';
import { deletePrompt,updatePrompt,createPrompt ,promptOptions} from '../slices/modelSlice';
import './TextModification.css'
const { TextArea } = Input;
const { Option } = Select;

export const TextModification = () => {
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [range, setRange] = useState([0, 40000]);
    // const [selectedVoice, setSelectedVoice] = useState('male');
    // const [value, setValue] = useState();
    const { modelName = [], setselectedModelName, selectedModelName, selectedLanguage, setselectedLanguage, setOutputLengthRange, outputLengthRange, setTemperatureRange, temperatureRange, topPRange, setTopPRange, topKRange, setTopKRange, repetitionPenaltyRange, setRepetitionPenaltyRange, maxSeq, setMaxSeq, setSystemPrompt, systemPrompt } = useContext(ModelContext)
    const { userId, AuthData } = useContext(LoginContext)
    const [editableOutputLength, setEditableOutputLength] = useState(outputLengthRange[0]);
    const [editTempInput, seteditTempInput] = useState(temperatureRange[0])
    const [editTopPInput, seteditTopPInput] = useState(topPRange[0])
    const [editTopKInput, seteditTopKInput] = useState(topKRange[0])
    const [editRepetitionInput, seteditRepetitionInput] = useState(repetitionPenaltyRange[0])
    const [editOption, setEditOption] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [addPromptName, setAddPromptName] = useState('');
    const [addSystemPrompt, setAddSystemPrompt] = useState('');

    const [options, setOptions] = useState([
        {
            key: 0,
            value: 'You are an assistant and your task is to help with user queries',
            label: 'Default',
        },
    ]);

    
    const modelOptions = (modelName || []).map((name) => ({
        value: name.model_name,
        label: name.modelName,
    }));
    const handleModelChange = (value) => {
        setselectedModelName(value);
        setOutputLengthRange([128, maxSeq])
        setEditableOutputLength(128)
    };
    useEffect(() => {
        // Find the selected model in the modelName array
        const selectedModel = modelName.find((model) => model.model_name === selectedModelName);

        // Set the maxSeq value if the selected model is found
        setMaxSeq(selectedModel ? selectedModel.max_seq : null);
    }, [selectedModelName, modelName, setMaxSeq]);

    // const showModal = () => {
    //     setIsModalOpen(true);
    // };

    const headers = {
        'api-key': AuthData,
};

    const getSystemPrompt = () => {
        dispatch(promptOptions({headers}))
            .then((res) => {
                const newOptions = res.payload.data.map(item => ({
                    key: item.prompt_id,
                    value: item?.prompt_value?.toString(),
                    label: item?.prompt_name?.toString(),
                }));
                // Filter out previous options (except the default one)
                const filteredOptions = options.filter(option => option.label === 'Default');
                setOptions([...filteredOptions, ...newOptions]);
                setIsModalOpen(false);
            })
            .catch((error) => {
                if (error.payload && error.payload.detail) {
                    message.error(error.payload.detail);
                } else {
                    message.error("Something went wrong! Contact Minsky Support Team");
                }
            });
    };

    

    useEffect(() => {
        getSystemPrompt();
    }, [])

const handleOk = () => {

    const requestBody = {
        user: userId?.toString(),
        prompt_name: addPromptName,
        prompt_value: addSystemPrompt,
    };

    const headers = {
        'Content-Type': 'application/json',
        'api-key': AuthData,
    };

    dispatch(createPrompt({ requestBody, headers }))
        .then((res) => {
            message.success(res.payload.message);
            getSystemPrompt();
            setIsModalOpen(false);
            setAddPromptName('');
            setAddSystemPrompt('');
        })
        .catch((error) => {
            if (error.payload && error.payload.detail) {
                message.error(error.payload.detail);
            } else {
                message.error('Something went wrong! Contact Minsky Support Team');
                setIsModalOpen(false);
            }
        });
};


    const handleEditOk = async () => {
        const systemPrompt = document.getElementById('systemPrompt').value;
        const requestBody = {
            "prompt_id": editOption.key,
            "prompt_value": systemPrompt,
        };
    
        dispatch(updatePrompt({ requestBody, headers })) 
        .then((res) => {
            message.success(res.payload?.message);
            setIsEditModalOpen(false); 
            setSystemPrompt(systemPrompt);
            getSystemPrompt();
        })
        .catch((error) => {
            if (error.response && error.response.data.detail) {
                message.error(error.response.data.detail);
            } else {
                message.error("Something went wrong! Contact Minsky Support Team");
            }
        })
        .finally(() => {
            setIsEditModalOpen(false); 
        });

    }
  
    

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const setSelectedSystemPrompt = (value) => {
        // const selectedOption = options.find(option => option.key === value);
        // console.log(selectedOption)
        setSystemPrompt(value);
    };

    const handleOutputLengthChange = (value) => {
        const clampedRange = Math.min(Math.max(value, 0), maxSeq);
        setOutputLengthRange([clampedRange, outputLengthRange[1]]);
        setEditableOutputLength(clampedRange);
    };
    const handleOutputEditableInputChange = (e) => {
        const inputValue = parseInt(e.target.value, 10) || 0;
        const clampedValue = Math.min(Math.max(inputValue, 0), maxSeq);
        setEditableOutputLength(clampedValue);
        setOutputLengthRange([clampedValue, maxSeq]);
    };
    // State and handler for Temperature
    const handleTemperatureChange = (newRange) => {
        const clampedRange = Math.min(Math.max(newRange, 0), 2);
        setTemperatureRange([clampedRange, temperatureRange[1]]);
        seteditTempInput(clampedRange);
    };
    const handleTempEditableInputChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (!isNaN(inputValue)) {
            const clampedValue = Math.min(Math.max(inputValue, 0), 2); // Ensure the value is between 0 and 2
            seteditTempInput(clampedValue);
            setTemperatureRange([clampedValue, maxSeq]);
        }
    };

    // State and handler for Top-P
    const handleTopPChange = (newRange) => {
        const clampedRange = Math.min(Math.max(newRange, 0), 2);
        setTopPRange([clampedRange, topPRange[1]]);
        seteditTopPInput(clampedRange);
    };
    const handleTopPEditableInputChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (!isNaN(inputValue)) {
            const clampedValue = Math.min(Math.max(inputValue, 0), 2);
            seteditTopPInput(clampedValue);
            setTopPRange([clampedValue, maxSeq]);
        }
    };

    // State and handler for Top-K
    const handleTopKChange = (newRange) => {
        const clampedRange = Math.min(Math.max(newRange, 0), 100);
        setTopKRange([clampedRange, topKRange[1]]);
        seteditTopKInput(clampedRange);
    };
    const handleTopKEditableInputChange = (e) => {
        const inputValue = parseInt(e.target.value, 10) || 0;
        const clampedValue = Math.min(Math.max(inputValue, 0), 100);
        seteditTopKInput(clampedValue);
        setTopKRange([clampedValue, maxSeq]);
    };

    // State and handler for Repetition Penalty
    const handleRepetitionPenaltyChange = (newRange) => {
        const clampedRange = Math.min(Math.max(newRange, 0), 2);
        setRepetitionPenaltyRange([clampedRange, repetitionPenaltyRange[1]]);
        seteditRepetitionInput(clampedRange);
    };
    const handleRepetitionEditableInputChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        if (!isNaN(inputValue)) {
            const clampedValue = Math.min(Math.max(inputValue, 0), 2);
            seteditRepetitionInput(clampedValue);
            setRepetitionPenaltyRange([clampedValue, maxSeq]);
        }
    };
    // const [hoveredOption, setHoveredOption] = useState(null);
    // const handleMouseEnter = (key) => {
    //     setHoveredOption(key);
    // };

    

    const handlePromptDelete = async () => {
        Modal.confirm({
            title: 'Are you sure you want to delete this prompt?',
            icon: null,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk: async () => {
                dispatch(deletePrompt({ prompt: editOption.key, headers })) // Dispatch deletePrompt action
                .then((res) => {
                    const updatedOptions = options.filter(option => option.key !== editOption.key);
                    setOptions(updatedOptions);
    
                    const defaultOption = options.find(option => option.label === 'Default');
                    setSelectedSystemPrompt(defaultOption.value);
                    setIsEditModalOpen(false);
                    message.success(res.payload?.message);
                })
                .catch((error) => {
                    console.error('There was a problem with the delete API request:', error);
                    message.error('Failed to delete prompt');
                });
            }
        });
    };
    

    const renderOption = (option) => {
        const isDefaultOption = option.key === 0;
        return (
            <div
                style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
                onMouseEnter={() => handleOptionHover(option)}
                onMouseLeave={() => handleOptionLeave(option)}
            >
                <span style={{ flex: 1 }}>{option.label}</span>
                {!isDefaultOption && (
                    <EditOutlined
                        id={`editIcon-${option.key}`}
                        className='system-prompt-edit'
                        onClick={() => handleEdit(option)}
                    />
                )}
            </div>
        );
    };
    const handleOptionHover = (option) => {
        // Make the Edit icon visible on hover
        const editIcon = document.getElementById(`editIcon-${option.key}`);
        if (editIcon) {
            editIcon.style.opacity = 1;
        }
    };

    const handleOptionLeave = (option) => {
        // Hide the Edit icon when leaving
        const editIcon = document.getElementById(`editIcon-${option.key}`);
        if (editIcon) {
            editIcon.style.opacity = 0;
        }
    };

    const handleEdit = (option) => {
        setEditOption(option);
        // Open the modal
        setIsEditModalOpen(true);
    };

    useEffect(() => {
    }, [selectedLanguage, setselectedLanguage, editOption]);



    return (
        <div>
            <div className='model-heading'>Model</div>
            <div className='model-dropdown'>
                <Select placeholder="Select a Model" optionFilterProp="children" options={modelOptions} value={selectedModelName} onChange={handleModelChange} style={{ width: '250px' }}>
                </Select>
            </div>
            <div className='modification-container'>
                <div className='main-modification'>
                    <span className='modification-title'>Modification</span>
                </div>
                <div className='modification-title' style={{ marginTop: '10px' }}>
                    <span className='system-prompt-title'>System Prompt</span>
                </div>
                <div className='system-prompt-dropdown'>
                    <Select
                        // showSearch={true}
                        style={{
                            width: 200,
                        }}
                        placeholder="Search to Select"
                        value={systemPrompt}
                        onChange={setSelectedSystemPrompt}
                    >
                        {options.map(option => (
                            <Option key={option.key} value={option.value}>
                                {renderOption(option)}
                            </Option>
                        ))}
                    </Select>
                    <button className='add-system-prompt-button'
                        onClick={() => {
                            setEditOption(null);
                            setIsModalOpen(true);
                        }}
                    >
                        <PlusCircleFilled />
                    </button>
                    <Modal
                        title="Add Prompt"
                        open={isModalOpen}
                        onCancel={handleCancel}
                        footer={[
                            <Button key="cancel" onClick={handleCancel}>
                                Cancel
                            </Button>,
                            <Button key="save" type="primary" onClick={handleOk}>
                                Save
                            </Button>,
                        ]}
                    >
                        <div className='main-add-system-prompt-fields'>
                            <Input placeholder='Prompt Name' id="addPromptName" value={addPromptName} onChange={(e) => setAddPromptName(e.target.value)} />
                            <TextArea placeholder='System Prompt' id="addSytemPrompt" value={addSystemPrompt} onChange={(e) => setAddSystemPrompt(e.target.value)} />
                        </div>
                    </Modal>
                    <Modal
                        title="Edit Prompt"
                        open={isEditModalOpen}
                        onCancel={() => setIsEditModalOpen(false)}
                        footer={[
                            <div className='edit-system-prompt'>
                                <div >
                                    <Button key="delete" type="primary" className='edit-model-danger' danger onClick={handlePromptDelete}>
                                        Delete
                                    </Button>
                                </div>
                                <div>
                                    <Button key="cancel" onClick={() => setIsEditModalOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button key="save" type="primary" onClick={handleEditOk}>
                                        Update
                                    </Button>
                                </div>
                            </div>
                        ]}
                    >
                        <div className='edit-system-prompt-fields' >
                            <Input
                                placeholder='Prompt Name'
                                id="promptName"
                                value={editOption ? editOption.label : ''}
                                readOnly
                            />
                            <TextArea
                                placeholder='System Prompt'
                                id="systemPrompt"
                                value={editOption ? editOption.value : ''}
                                onChange={(e) => setEditOption({ ...editOption, value: e.target.value })}
                            />
                        </div>
                    </Modal>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <span className='parameters-title'>Parameters</span>
                    <div className='main-parameters'>
                        <div style={{ width: '90%' }}>
                            <div className='parameters-container'>
                                <span>Output Length</span>
                                <span className='parameter-text-edit'><Input
                                    value={editableOutputLength}
                                    onChange={handleOutputEditableInputChange}
                                    className='parameter-edit-field'
                                /></span>
                            </div>
                            <div>
                                <Slider
                                    min={0}
                                    max={maxSeq ? maxSeq : 600}
                                    step={1}
                                    value={outputLengthRange}
                                    onChange={handleOutputLengthChange}
                                />
                            </div>

                            <div className='parameters-container'>
                                <span>Temperature</span>
                                <span className='parameter-text-edit'><Input
                                    value={editTempInput}
                                    onChange={handleTempEditableInputChange}
                                    className='parameter-edit-field'
                                /></span></div>
                            <div>
                                <Slider
                                    min={0}
                                    max={2}
                                    step={0.1}
                                    value={temperatureRange}
                                    onChange={handleTemperatureChange}
                                />
                            </div>
                            <div className='parameters-container'>
                                <span>Top-P</span>
                                <span className='parameter-text-edit'><Input
                                    value={editTopPInput}
                                    onChange={handleTopPEditableInputChange}
                                    className='parameter-edit-field'
                                /></span></div>
                            <div>
                                <Slider
                                    min={0}
                                    max={2}
                                    step={0.1}
                                    value={topPRange}
                                    onChange={handleTopPChange}
                                />
                            </div>
                            <div className='parameters-container'>
                                <span>Top-K</span>
                                <span className='parameter-text-edit'><Input
                                    value={editTopKInput}
                                    onChange={handleTopKEditableInputChange}
                                    className='parameter-edit-field'
                                /></span></div>
                            <div>
                                <Slider
                                    min={0}
                                    max={100}
                                    step={1}
                                    value={topKRange}
                                    onChange={handleTopKChange}
                                />
                            </div>
                            <div className='parameters-container'>
                                <span>Repetition Penalty</span>
                                <span className='parameter-text-edit'><Input
                                    value={editRepetitionInput}
                                    onChange={handleRepetitionEditableInputChange}
                                    className='parameter-edit-field'
                                /></span></div>
                            <div>
                                <Slider
                                    min={0}
                                    max={2}
                                    step={0.1}
                                    value={repetitionPenaltyRange}
                                    onChange={handleRepetitionPenaltyChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
