import { Input, Button, message } from 'antd'
import { CopyOutlined } from '@ant-design/icons';
import React, { useRef, useState, useContext} from 'react';
import { LoginContext } from '../ContextApis/LoginContext';
import {generateKey} from "../slices/generateKeySlice"
import { useDispatch } from "react-redux";


export const APIKeys = () => {

  const dispatch = useDispatch();

  const inputRef = useRef(null);
  const { AuthData, setAuthData,setIsAuthenticated } = useContext(LoginContext);
  const [loading, setloading] = useState(false)

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };
 




  const generateAPIKey = async () => {
    setloading(true);
  
    const headers = {
      'Content-Type': 'application/json',
      'api-key': AuthData
    };
  
    dispatch(generateKey({ headers }))
      .then((res) => {
        
        // console.log("Generate Key Response",res)
        message.success("Generated Key Successfully! Please Login again.");
        // console.log("Generated Key", res);
        sessionStorage.clear();
        setIsAuthenticated(false)
        setloading(false);
      })
      .catch((error) => {
        // Handle error
        if (error.payload && error.payload.detail) {
          message.error(error.payload.detail);
        } else {
          message.error("Something went wrong! Contact Minsky Support Team");
        }
        setloading(false);
      });
  };
  

  return (
    <div>
      <div className='api-key-heading'>
        <span >API Key</span>
      </div>
      <div style={{ display: "flex" }}>
        <Input ref={inputRef} type="text" placeholder="token" value={AuthData ? AuthData : "No token"}></Input>
        <Button onClick={handleCopy} icon={<CopyOutlined />}>
          Copy to Clipboard
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
        <Button type="primary" onClick={generateAPIKey} loading={loading}>
          Generate New Token
        </Button>
      </div>
    </div>
  )
}
