import React from "react";
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { register } from "../slices/auth";
import { message } from "antd";
import { useDispatch } from "react-redux";


import * as Yup from "yup";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  hash_password: "",
  mobile_number: "",
};

const signupValidationSchema = Yup.object({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  hash_password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("hash_password"), null], "Passwords must match")
    .required("Confirm Password is required"),
    mobile_number: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Mobile number must contain only digits")
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits"),
});

const SignUp = ({loginStatus}) => {


  console.log(loginStatus)

  const [passwordShown, setPasswordShown] = useState(false);
  // const [action, setaction] = useState("Login");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const sha256Hash = async (data) => {
    const encoder = new TextEncoder();
    const dataUint8 = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-256", dataUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashedPassword = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
    return hashedPassword;
  };

  const handleSubmit = async (values) => {
    console.log("Form Submitted", values.hash_password);

    const hashedPassword = await sha256Hash(values.hash_password);

    const requestData = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      hash_password: hashedPassword,
      mobile_number: values.mobile_number,
    };

    
    dispatch(register(requestData)) .then((res) => {   
        console.log("Register Response : :",res); 
        message.success(res?.payload?.message);


        
    }).catch((err) => {
        console.log("Error : :", err);
        
      });
  };

  return (
    <div className="form-container">
      <div className="header">
        <div className="text">Sign up</div>
        <div className="underline"></div>
      </div>
      <div className="main-navigation-login">
        <span className="login-question">Have an account? </span>
        <span
          className="login-statement"
          onClick={() => {
            navigate("/*");
          }}
        >
          Login
        </span>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={signupValidationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <div class="container">
              <div class="row">
                <div class="col">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      maxLength={255}
                      id="signup_first_name"
                      placeholder="First Name"
                      type="text"
                      name="first_name"
                    />
                    <label htmlFor="Signup_first_name">
                      First Name<span className="gl-form-asterisk"></span>
                    </label>
                    <ErrorMessage name="first_name">
                      {(msg) => (
                        <div
                          className="abhitest"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "51px",
                            zIndex: " 999",
                            fontSize: "small",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div class="col">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      maxLength={255}
                      id="signup_first_name"
                      placeholder="Last Name"
                      type="text"
                      name="last_name"
                    />
                    <ErrorMessage name="last_name">
                      {(msg) => (
                        <div
                          className="abhitest"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "51px",
                            zIndex: " 999",
                            fontSize: "small",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>

                    <label htmlFor="Signup_first_name">
                      Last Name<span className="gl-form-asterisk"></span>
                    </label>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      placeholder="Email"
                      type="text"
                      name="email"
                    />
                    <label>
                      Email<span className="gl-form-asterisk"></span>
                    </label>
                    <ErrorMessage name="email">
                      {(msg) => (
                        <div
                          className="abhitest"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "51px",
                            zIndex: " 999",
                            fontSize: "small",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div class="col">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      placeholder="Password"
                      type={passwordShown ? "text" : "password"}
                      name="hash_password"
                    />

                    <label htmlFor="form_name_signup_passoword">
                      Password<span className="gl-form-asterisk"></span>
                    </label>
                    <i
                      htmlFor="form_name_signup_passoword"
                      className="eye-icon"
                      onClick={togglePasswordVisiblity}
                    >
                      {passwordShown ? (
                        <EyeTwoTone />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </i>
                    <ErrorMessage name="hash_password">
                      {(msg) => (
                        <div
                          className="abhitest"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "51px",
                            zIndex: " 999",
                            fontSize: "small",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      id="form_name_signup_confirmpassoword"
                      placeholder="Confirm Password"
                      type={passwordShown ? "text" : "password"}
                      name="confirm_password"
                    />

                    <label htmlFor="form_name_signup_confirmpassoword">
                      Confirm Password<span className="gl-form-asterisk"></span>
                    </label>
                    <i className="eye-icon" onClick={togglePasswordVisiblity}>
                      {passwordShown ? (
                        <EyeTwoTone />
                      ) : (
                        <EyeInvisibleOutlined />
                      )}
                    </i>
                    <ErrorMessage name="confirm_password">
                      {(msg) => (
                        <div
                          className="abhitest"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "51px",
                            zIndex: " 999",
                            fontSize: "small",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div class="col">
                  <div className="form-group">
                    <Field
                      className="form-control"
                      id="signup_mobile"
                      placeholder="Mobile Number"
                      name="mobile_number"
                    />

                    <label htmlFor="Signup_mobile">
                      Mobile<span className="gl-form-asterisk"></span>
                    </label>
                    <ErrorMessage name="mobile_number">
                      {(msg) => (
                        <div
                          className="abhitest"
                          style={{
                            color: "red",
                            position: "absolute",
                            top: "51px",
                            zIndex: " 999",
                            fontSize: "small",
                          }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="submit-container">
                  <button
                    type="submit"
                    className="submit"
                    // disabled={
                    //     !(formik.isValid && formik.dirty) ? false : true
                    //   }
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUp;
