import React, { useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd';
import { forgetPassword } from '../slices/auth';
import { useDispatch } from "react-redux";

export const ForgetPassword = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const dispatch = useDispatch();




    const onFinish = async (values) => {
        const lastName = values.lname
        const email = values.user

        dispatch(forgetPassword({
            user:email,
            lname:lastName
            
        }))
        .then((res) => {   
            console.log("res",res) 
            message.success(res?.payload?.message);
        })
        .catch((err) => {
          console.log("Error : :", err);
          message.error(err.res?.payload?.detail);
        });

        setIsModalVisible(false);
    };
    return (
        <>
            <span className="forget-password" onClick={showModal}>
                Forget Password? <span>Click here!</span>
            </span>
            <Modal
                title="Forget Password"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null} // Remove the default OK and Cancel buttons
            >
                <Form onFinish={onFinish}>
                    <Form.Item
                        name="user"
                        label="Email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please enter a valid email!',
                            },
                            {
                                required: true,
                                message: 'Please enter your email!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name="lname"
                        label="Last Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter your last name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
